import React, { useContext } from "react"
import Typography from "components/Typography"
import Audio from "components/Player/Audio"
import { ItemContainer, HeadContent, HeadImgContainer, HeadImg } from "./styles"
import { GalleryModalContext } from "../../store/GalleryModalContext"
import { isMobile } from "utils"

const WiseHeads = ({ wiseheads }) => {

  const modalCtx = useContext(GalleryModalContext)

  const getFirstName = (name) => {
    const first = name.split(" ")[0];
    const second = name.split(" ")[1];
    if (first === "prof.") {
      return first + " " + second;
    } else {
      return first;
    }
  }

  const getLastName = (name) => {
    return name.slice(name.lastIndexOf(' ') + 1);
  }

  return (
    <ul style={{ marginTop: "3.5em", marginBottom: "8em" }}>
      {wiseheads?.map((head, index) => {
        return (
          <ItemContainer key={index}>
            <HeadImgContainer>
              <HeadImg src={head.image.url} alt={head.image.alt} onClick={()=>modalCtx.setGalleryModal(head)} />
            </HeadImgContainer>
            <HeadContent>
              <Typography
                variant="name"
                color="neutral.ultraLight">
                {getFirstName(head.name)}
              </Typography>
              <Typography
                variant="lastName"
                color="accent.red"
                paddingBottom="20px"
                style={{wordWrap: isMobile ? "break-word" : "normal"}}>
                {getLastName(head.name)}
              </Typography>
              <Typography
                variant="bodySection"
                color="text.primary"
                paddingBottom="40px"
                fontSize={isMobile ? "14px" : "16px"}
                lineHeight={isMobile ? "27px" : "30px"}>
                {head.description}
              </Typography>
              <Audio track={head.audio} />
              {head.audio2.url !== "" &&
                <Audio track={head.audio2} />
              }
            </HeadContent>
          </ItemContainer>
        )
      })}
    </ul>
  )
}

export default WiseHeads
