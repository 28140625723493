import styled from "styled-components"

export const ItemContainer = styled.li`
  display: flex;
  margin-bottom: 122px;

  @media (max-width: 768px) {
      display: block;
  }
`
export const HeadContent = styled.div`
  margin-left: 52px;
  margin-top: -45px;

  @media (max-width: 768px) {
    margin: 0;
  }
`
export const HeadImgContainer = styled.div`
  border: 1px dashed rgba(218,210,210,0.1);
  height: 302px !important;
  width: 266px !important;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: -20px;
  margin-left: -20px;

  @media (max-width: 768px) {
    border: none;
    margin: 0;
    position: static;
    height: 174px !important;
    width: 154px !important;
    display: inline;
  }
`
export const HeadImg = styled.img`
  height: 302px !important;
  width: 266px !important;
  position: absolute;
  margin-top: -20px;
  margin-left: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    height: 174px !important;
    width: 154px !important;
    margin-top: 0;
    margin-left: 0;
    position: static;
    float: left;
    margin-right: 22px;
  }
`